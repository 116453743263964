import i18n from "i18next";
import Cache from "i18next-localstorage-cache";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const initI18n = () => {
i18n
    .use(Backend)
    .use(Cache)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            fallbackLng: "en",            
            backend: { loadPath: `/locales/{{lng}}.json`},
            react: {
                bindI18n: 'loaded languageChanged',
                bindI18nStore: 'added',
                useSuspense: true
              }
        }
    )
}

export default initI18n;