import React, { useState, useEffect, useContext, createRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import ClientLocationsService from "../../../services/clientLocationsService";
import ClientLocation from '../../../models/clientLocation';
import OpeningHours from "../../../models/openingHours";

import SimpleList from "./../../simpleList";
import LoadingPage from "./../administration/loadingPage";
import PatientsService from '../../../services/patientsService';
import OpeningHoursInput from '../../openingHoursInput';
import BackButton from '../../backButton';
import SaveButton from '../../saveButton';
import SaveStatus from '../../../models/saveStatus';
import VisitMotivesService from '../../../services/visitMotivesService';
import AppointmentsService from '../../../services/appointmentsService';
import ProgressDialog from '../../dialogs/progressDialog';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import UploadIcon from "@mui/icons-material/CloudUpload";
import ImportDialog from '../../dialogs/importDialog';
import { GlobalContext } from '../../../GlobalContext';

function ClientPage() {

    const navigate = useNavigate();

    const inputFileRef = createRef<HTMLInputElement>();

    let { clientLocationId } = useParams();

    const { settings } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);

    const [clientLocation, setClientLocation] = useState(new ClientLocation());

    const [name, setName] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [googleMapsLink, setGoogleMapsLink] = useState("");

    const [isEnabled, setIsEnabled] = useState(true);

    const [email, setEmail] = useState("");
    const [invoiceEmail, setInvoiceEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [faxNumber, setFaxNumber] = useState("");
    const [homepage, setHomepage] = useState("");

    const [publicTransports, setPublicTransports] = useState<string[]>([]);
    const [accessInformation, setAccessInformation] = useState<string[]>([]);

    const [languages, setLanguages] = useState<string[]>([]);
    const [profile, setProfile] = useState("");
    const [openingHours, setOpeningHours] = useState(new OpeningHours());

    const [logoUrl, setLogoUrl] = useState("");

    const [isAddressVisible, setIsAddressVisible] = useState(false);
    const [isOpenHoursVisible, setIsOpenHoursVisible] = useState(false);
    const [isLogoVisible, setIsLogoVisible] = useState(false);
    const [customBookingButtonText, setCustomBookingButtonText] = useState("");
    const [customBookingHeadlineText, setCustomBookingHeadlineText] = useState("");
    const [customBookingSubHeadlineText, setCustomBookingSubHeadlineText] = useState("");
    const [customDoctorDesignation, setCustomDoctorDesignation] = useState("");


    const [showVirtualAppointments, setShowVirtualAppointments] = useState(false);

    const [isProgressVisible, setIsProgressVisible] = useState(false);

    const [isUploadDialogVisible, setIsUploadDialogVisible] = useState(false);

    const [isPatientImportDialogVisible, setIsPatientImportDialogVisible] = useState(false);

    const { isLoading, setIsLoading } = useContext(GlobalContext);

    useEffect(() => {

        loadData();

        // eslint-disable-next-line
    }, [clientLocationId]);


    async function loadData() {

        if (clientLocationId && clientLocationId.toLowerCase() === "create") {
            const newClientLocation = new ClientLocation();
            newClientLocation.clientId = currentClient.id;
            setClientLocation(newClientLocation);

            setOpeningHours(newClientLocation.openingHours.clone());

        } else {

            if (!clientLocationId) return;

            setIsLoading(true);

            try {


                const result = await ClientLocationsService.getLocation(currentClient.id, clientLocationId);

                setIsLoading(false);

                if (result) {

                    setOpeningHours(result.openingHours.clone());

                    setClientLocation(result);

                    setName(result.name ? result.name : "");
                    setStreet(result.street ? result.street : "");
                    setCity(result.city ? result.city : "");
                    setPostalCode(result.postalCode ? result.postalCode : "");

                    setGoogleMapsLink(result.googleMapsLink ? result.googleMapsLink : "");

                    setIsEnabled(result.isEnabled);

                    setAccessInformation(result.accessInformation ? result.accessInformation : []);
                    setPublicTransports(result.publicTransports ? result.publicTransports : []);

                    setPhoneNumber(result.phoneNumber ? result.phoneNumber : "");
                    setFaxNumber(result.faxNumber ? result.faxNumber : "");
                    setEmail(result.email ? result.email : "");
                    setInvoiceEmail(result.invoiceEmail ? result.invoiceEmail : "");
                    setHomepage(result.homepage ? result.homepage : "");

                    setLogoUrl(result.logoUrl ?? "");

                    setLanguages(result.languages ? result.languages : []);
                    setProfile(result.profile);

                    setShowVirtualAppointments(result.showVirtualAppointments ?? false);

                    setIsAddressVisible(result.isAddressVisible);
                    setIsOpenHoursVisible(result.isOpenHoursVisible);
                    setIsLogoVisible(result.isLogoVisible);
                    setCustomBookingButtonText(result.customBookingButtonText);
                    setCustomBookingHeadlineText(result.customBookingHeadlineText);
                    setCustomBookingSubHeadlineText(result.customBookingSubHeadlineText);
                    setCustomDoctorDesignation(result.customDoctorDesignation);
                }


            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        clientLocation.name = name;
        clientLocation.street = street;
        clientLocation.city = city;
        clientLocation.postalCode = postalCode;

        clientLocation.googleMapsLink = googleMapsLink;

        clientLocation.isEnabled = isEnabled;

        clientLocation.accessInformation = accessInformation;
        clientLocation.publicTransports = publicTransports;

        clientLocation.phoneNumber = phoneNumber;
        clientLocation.faxNumber = faxNumber;
        clientLocation.email = email;
        clientLocation.invoiceEmail = invoiceEmail;
        clientLocation.homepage = homepage;

        clientLocation.logoUrl = logoUrl;

        clientLocation.languages = languages;
        clientLocation.profile = profile;

        clientLocation.openingHours = openingHours.clone();

        clientLocation.showVirtualAppointments = showVirtualAppointments;

        clientLocation.isAddressVisible = isAddressVisible;
        clientLocation.isOpenHoursVisible = isOpenHoursVisible;
        clientLocation.isLogoVisible = isLogoVisible;
        clientLocation.customBookingButtonText = customBookingButtonText;
        clientLocation.customBookingHeadlineText = customBookingHeadlineText;
        clientLocation.customBookingSubHeadlineText = customBookingSubHeadlineText;
        clientLocation.customDoctorDesignation = customDoctorDesignation;

        const clientLocationId = await ClientLocationsService.updateClientLocation(clientLocation);
        if (clientLocationId) {
            navigate("/settings/clientlocations");
        }

    }


    function cloneFromMasterVisitMotives() {
        if (!clientLocationId) return;

        const realyOverwrite = window.confirm("Wollen Sie die Master Besuchsgründe importieren? Damit überschreiben Sie ALLE Besuchsgründe des Kundenstandortes!");
        if (realyOverwrite) {
            const check = window.prompt("Bitte geben Sie das Master-Passwort ein um fortzufahren.");
            if (check === "pad164888") {
                VisitMotivesService.cloneFromMasterVisitMotives(currentClient.id, clientLocationId);
            } else {
                alert("Das Password ist falsch!");
            }
        }
    }

    function cloneToMasterVisitMotives() {
        if (!clientLocationId) return;

        const realyOverwrite = window.confirm("Wollen Sie die Master Besuchsgründe überschreiben? Damit überschreiben Sie ALLE Master Besuchsgründe!");
        if (realyOverwrite) {
            const check = window.prompt("Bitte geben Sie das Master-Passwort ein um fortzufahren.");
            if (check === "pad164888") {
                VisitMotivesService.cloneToMasterVisitMotives(currentClient.id, clientLocationId);
            } else {
                alert("Das Password ist falsch!");
            }
        }
    }

    async function updatePatientsIndexes() {

        if (!clientLocationId) return;

        const realyUpdate = window.confirm("Wollen Sie die Patienten Indexe updaten?");
        if (realyUpdate) {
            const check = window.prompt("Bitte geben Sie das Master-Passwort ein um fortzufahren.");
            if (check === "pad164888") {
                setIsProgressVisible(true);
                await PatientsService.updatePatientsIndexes(currentClient.id, clientLocationId);
                setIsProgressVisible(false);
            } else {
                alert("Das Password ist falsch!");
            }
        }
    }

    async function exportPatients() {

        if (!clientLocationId) return;

        const realyUpdate = window.confirm("Wollen Sie die Patienten exportieren?");
        if (realyUpdate) {
            const check = window.prompt("Bitte geben Sie das Master-Passwort ein um fortzufahren.");
            if (check === "pad164888") {
                setIsProgressVisible(true);
                await PatientsService.startPatientsExport(currentClient.id, clientLocationId);
                setIsProgressVisible(false);

                const storage = getStorage();
                const storageRef = ref(storage, `clients/${currentClient.id}/locations/${clientLocationId}/patientsexport.csv`);

                const url = await getDownloadURL(storageRef);

                alert(url);
                console.log(url);
            } else {
                alert("Das Password ist falsch!");
            }
        }
    }

    function importPatients(x) {
        // if (inputFileRef.current) {
        //     inputFileRef.current.click();
        // }

        setIsPatientImportDialogVisible(true);
    }

    function handleOpeningHoursChange(changedOpeningHours: OpeningHours) {
        if (changedOpeningHours) {
            setOpeningHours(changedOpeningHours.clone());
        }
    }

    async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {

        if (e.target.files && e.target.files.length === 1) {
            const file = e.target.files[0];
            const fileName = file.name.toLowerCase();

            if (!fileName.endsWith(".json")) {
                alert("Falsches Dateiformat!\nBitte wählen Sie eine gültige Export Datei im JSON Format.")
            } else {

                if (clientLocation.id) {

                    // const importSuccess = await PatientsService.startDeletePatientsImport(currentClient.id, clientLocation.id);
                    // return;

                    try {
                        setIsProgressVisible(true);

                        const storage = getStorage();

                        let storageRef: any = null;

                        if (fileName.indexOf("appointment-export") !== -1) {
                            storageRef = ref(storage, `clients/${currentClient.id}/locations/import-appointment-${clientLocation.id}.json`);
                        } else {
                            storageRef = ref(storage, `clients/${currentClient.id}/locations/import-${clientLocation.id}.json`);
                        }

                        await uploadBytes(storageRef, file);

                        let importSuccess = false;

                        if (fileName.indexOf("appointment-export") !== -1) {
                            const utcOffset = moment().utcOffset();

                            importSuccess = await AppointmentsService.startImport(
                                currentClient.id,
                                clientLocation.id,
                                "QIh6mx3xdupCYPCuVjvc",
                                "Dampsoft Import",
                                "#00ffee",
                                "raDtjC8zDUxnRj4dTAgh",
                                utcOffset
                            );
                        } else {
                            importSuccess = await PatientsService.startImport(currentClient.id, clientLocation.id);
                        }


                        setIsProgressVisible(false);

                        if (importSuccess) {
                            alert("Die Datei wurde erfolgreich importiert: " + fileName);
                        } else {
                            alert("Die Datei konnte nicht importiert werden!");
                        }

                    } catch (error: any) {
                        alert("Die Datei konnte nicht importiert werden!\n" + (error?.message ? error.message : ""));
                    }
                } else {
                    alert("Bitte speichern Sie zuerst diesen Standort und starten dann erneut den Import.");
                }
            }

        }

        // clear input, so next time "handleFileChange" is triggered even if the file name is the same
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
    }

    async function handleLogoChange(files: FileList | null) {
        if (files && files.length > 0 && clientLocationId) {

            // first check file size (has to <= 20mb)
            if (files[0] && files[0].size > 20000000) {
                alert("Diese Datei ist zu groß. Sie können nur Dateien mit einer Größe von maximal 20 MB hochladen.");
                return "";
            }

            setIsUploadDialogVisible(true);
            const downloadUrl = await ClientLocationsService.uploadLocationLogo(files[0], currentClient.id, clientLocationId);
            setIsUploadDialogVisible(false);

            setLogoUrl(downloadUrl);
        } else {
            setLogoUrl("");
        }
    }

    if (isLoading) return <LoadingPage />;

    if ((!clientLocation && clientLocationId !== "create") || !settings) return (
        <div className="kt-page-content">
            <h2>Standort</h2>

            <div>Dieser Standort existiert nicht. Bitte überprüfen Sie Ihre URL.</div>
        </div>
    );

    return (
        <Box className="kt-page">

            <ProgressDialog
                visible={isUploadDialogVisible}
                title="Datei Upload"
                message="Ihre Datei wird hochgeladen..."
            />

            <ImportDialog
                visible={isPatientImportDialogVisible}
                onClose={() => setIsPatientImportDialogVisible(false)}
            />

            <h2>Standort - {name}</h2>

            <ProgressDialog
                visible={isProgressVisible}
                title="Die Datenbank wird aktualisiert..."
                message="Dies kann einige Minuten dauern. Bitte schließen Sie nicht das Browserfenster."
            />

            <form onSubmit={handleSubmit} autoComplete="turnitoff">

                <div className="kt-form-section">
                    <h3>Standort</h3>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="street"
                                label="Straße"
                                value={street}
                                onChange={e => setStreet(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="postalCode"
                                label="Postleitzahl"
                                value={postalCode}
                                onChange={e => setPostalCode(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="city"
                                label="Stadt"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="googleMapsLink"
                                label="Google Maps Link"
                                value={googleMapsLink}
                                onChange={e => setGoogleMapsLink(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} className="kt-logo-upload">
                            {clientLocationId !== "create" &&
                                <React.Fragment>
                                    <img className="kt-logo" src={logoUrl} />
                                    <Button
                                        variant="contained"
                                        component="label"
                                    >
                                        Logo wählen
                                        <input
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            onChange={(e) => handleLogoChange(e.target.files)}
                                        />
                                    </Button>
                                </React.Fragment>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            <h4>Öffnungszeiten</h4>
                            <OpeningHoursInput
                                showEnabledCheckbox={false}
                                openingHours={openingHours}
                                onInputChange={handleOpeningHoursChange}
                            />
                        </Grid>
                    </Grid>



                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <h4>Zugangsinformationen</h4>
                            <SimpleList listItems={publicTransports} onListItemsChange={(_publicTransports) => setPublicTransports(_publicTransports)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Öffentlicher Nahverkehr (ÖPNV)</h4>
                            <SimpleList listItems={accessInformation} onListItemsChange={(_accessInformation) => setAccessInformation(_accessInformation)} />
                        </Grid>
                    </Grid>

                </div>

                <div className="kt-form-section">
                    <h3>Kontakt</h3>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="phoneNumber"
                                label="Telefon"
                                value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name="faxNumber"
                                label="Fax"
                                value={faxNumber}
                                onChange={e => setFaxNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="email"
                                name="email"
                                label="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="url"
                                name="homepage"
                                label="Homepage"
                                value={homepage}
                                onChange={e => setHomepage(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="email"
                                name="invoiceEmail"
                                label="Rechnungs-Email"
                                value={invoiceEmail}
                                onChange={e => setInvoiceEmail(e.target.value)}
                            />
                        </Grid>

                    </Grid>
                </div>


                <div className="kt-form-section">
                    <h3>Profil</h3>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <h4>Sprachen</h4>
                            <SimpleList listItems={languages} onListItemsChange={(_languages) => setLanguages(_languages)} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <h4>Begrüßungstext</h4>
                            <TextField
                                rows={4}
                                fullWidth
                                multiline
                                name="profile"
                                label="Begrüßungstext"
                                value={profile}
                                onChange={e => setProfile(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <h4>Öffentliches Praxisprofil</h4>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isAddressVisible}
                                        onChange={(e) => setIsAddressVisible(e.target.checked)}
                                    />
                                }
                                label="Addresse sichtbar"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isLogoVisible}
                                        onChange={(e) => setIsLogoVisible(e.target.checked)}
                                    />
                                }
                                label="Logo sichtbar"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isOpenHoursVisible}
                                        onChange={(e) => setIsOpenHoursVisible(e.target.checked)}
                                    />
                                }
                                label="Öffnungszeiten sichtbar"
                            />
                            <TextField value={customBookingHeadlineText} onChange={(e) => setCustomBookingHeadlineText(e.target.value)} label="Überschrift für die Profilseite" fullWidth />
                            <TextField value={customBookingSubHeadlineText} onChange={(e) => setCustomBookingSubHeadlineText(e.target.value)} label="Zweite Überschrift für die Profilseite" fullWidth />
                            <TextField value={customBookingButtonText} onChange={(e) => setCustomBookingButtonText(e.target.value)} label="Text für den Buchungsbutton" fullWidth />
                            <TextField value={customDoctorDesignation} onChange={(e) => setCustomDoctorDesignation(e.target.value)} label="Bezeichnung für den Mitarbeiter (z.B. Arzt, Mitarbeiter)" fullWidth />

                        </Grid>


                    </Grid>

                </div>

                <div className='kt-form-section'>
                    <h3>Anzeige</h3>
                    <Grid container>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showVirtualAppointments}
                                        onChange={(e) => setShowVirtualAppointments(e.target.checked)}
                                    />
                                }
                                label="Virtuelle Termine anzeigen"
                            />
                        </Grid>
                    </Grid>
                </div>

                <input type="file" ref={inputFileRef} style={{ display: 'none' }} onChange={handleFileChange} />

                {/* <div className="kt-button-bar">
                    <div className="kt-btn" onClick={cloneFromMasterVisitMotives}><i className="fal fa-file-import"></i> Aus den Master Besuchsgründen importieren</div>
                    <div className="kt-btn" onClick={cloneToMasterVisitMotives}><i className="fal fa-file-import"></i> Mit diesen Einstellungen die Master Besuchsgründe überschreiben</div>
                    <div className="kt-btn" onClick={updatePatientsIndexes}>Patienten Indexe updaten</div>
                    <div className="kt-btn" onClick={exportPatients}>Patienten exportieren</div>
                </div> */}

                <div className="kt-page-footer">
                    <BackButton />

                    <Button variant="contained" startIcon={<UploadIcon />} onClick={importPatients}>PATIENTEN IMPORTIEREN</Button>

                    <SaveButton saveStatus={SaveStatus.none} />
                </div>


            </form>

        </Box>
    );
}

export default ClientPage;