import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CopyIcon from "@mui/icons-material/FileCopy";
import PDocument from '../../../../src/shared/src/models/formEditor/pdocument';
import DocumentsService from '../../../services/documentsService';
import DateUtils from '../../../../src/shared/src/utils/dateUtils';
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import { isMobileDevice, updateRowPosition } from '../../../utils';
import { GlobalContext } from '../../../GlobalContext';
import { AppBar, Dialog, DialogTitle, Toolbar, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormPreview from '../../formEditor/formPreview';
import ClientLocationsService from '../../../services/clientLocationsService';
import CloseIcon from '@mui/icons-material/Close';

function DocumentsPage() {

    const navigate = useNavigate();

    const [pdocuments, setDocuments] = useState([] as PDocument[]);
    const [selectedDocument, setSelectedDocument] = useState<PDocument | null>(null);

    const [clientLocationLogoUrl, setClientLocationLogoUrl] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const { currentClient } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);

    const [formPreviewVisible, setFormPreviewVisible] = useState(false);

    useEffect(() => {

        if (currentClient) {
            updateDocuments();
        }

        // eslint-disable-next-line
    }, [currentClient]);



    async function updateDocuments() {

        if (!currentUser) {
            return;
        }

        setIsLoading(true);

        try {
            const result = await DocumentsService.getDocumentTemplates(currentClient.id, currentUser.locationId);

            setIsLoading(false);
            if (result) {
                setDocuments(result)
            }

        } catch (error) {
            setIsLoading(false);
        }
    }


    const columns: any[] = [
        {
            field: 'name', headerName: 'Name', width: 300, renderCell: (params) => (<>
                {isMobileDevice() ? 
                    <span onClick={() => onPreviewRowClick(params.row)}>{params.row.name}</span>
                    :                
                <Link to={`/settings/document/${params.row.id}`}>{params.row.name}</Link>}
                </>
            )
        },
        // { field: 'language', headerName: ' ', width: 64, renderCell: (params) => (
        //     <ReactCountryFlag countryCode={params.row.language.toUpperCase()} svg />
        // )},
        {
            field: 'expiresAfter', headerName: 'Gültig', width: 120, renderCell: (params) => (
                <span>{DateUtils.formatInterval(params.row.expiresAfter)}</span>
            )
        },
        {
            field: 'mandatory', headerName: 'Pflicht', width: 120, renderCell: (params) => (
                params.row.mandatory ? <CheckRoundedIcon /> : <></>
            )
        },
        {
            field: 'delete', headerName: " ", width: 128, renderCell: (params) => (
                <React.Fragment>
                    <Tooltip title="Vorschau" placement="left">
                        <IconButton onClick={(e) => onPreviewRowClick(params.row)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Duplizieren" placement="left">
                        <IconButton onClick={(e) => onCloneRowClick(params.row)}>
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Löschen" placement="right">
                        <IconButton onClick={(e) => onDeleteRowClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>)
        }
    ];


    async function onPreviewRowClick(row) {
        if (currentUser) {
            setIsLoading(true);

            if (clientLocationLogoUrl === "") {
                async function updateLogo() {
                    if (currentClient && currentUser) {
                        const location = await ClientLocationsService.getLocation(currentClient.id, currentUser?.locationId);

                        if (location) setClientLocationLogoUrl(location.logoUrl);
                    }
                }

                updateLogo();
            }

            const _document = await DocumentsService.getDocumentTemplate(currentClient.id, currentUser.locationId, row.id);
            if (_document) {
                setSelectedDocument(_document);

                setFormPreviewVisible(true);
            }

            setIsLoading(false);

        }
    }

    async function onCloneRowClick(row) {

        if (currentUser) {
            await DocumentsService.cloneDocumentTemplate(row.id, currentClient.id, currentUser.locationId);
            updateDocuments();
        }
    }

    async function onDeleteRowClick(row) {
        const reallyDelete = window.confirm(`Wollen Sie das Dokument ${row.name} wirklich löschen?`);
        if (reallyDelete && currentUser) {
            await DocumentsService.deleteDocumentTemplate(row.id, currentClient.id, currentUser.locationId);
            updateDocuments();
        }
    }


    async function onCreateDocumentClick() {
        const newDoc = new PDocument();
        if (currentUser) {
            const newDocId = await DocumentsService.updateDocumentTemplate(newDoc, currentClient.id, currentUser.locationId);

            if (newDocId !== null) {
                navigate(`/settings/document/${newDocId}`);
            }
        }
    }

    const handleRowOrderChange = async (params) => {
        setIsLoading(true);
        const newRows = updateRowPosition<PDocument>(
            params.oldIndex,
            params.targetIndex,
            pdocuments,
        );

        for (let i = 0; i < newRows.length; i++) {
            const e = newRows[i];
            e.cardinality = i + 1;

            DocumentsService.updateDocumentTemplate(e, currentClient.id, currentUser!.locationId);
        }
        setDocuments(newRows);
        setIsLoading(false);
    };



    return (
        <>
            {selectedDocument && <Dialog
                fullScreen
                onClose={() => setFormPreviewVisible(false)}
                aria-labelledby="simple-dialog-title"
                open={formPreviewVisible}
            >

                <AppBar>
                    <Toolbar>
                        <DialogTitle>Formular Vorschau</DialogTitle>

                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setFormPreviewVisible(false)}
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 20,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <div>
                    <FormPreview
                        documentId={selectedDocument.id}
                        clientId={currentClient.id}
                        locationId={currentUser!.locationId}
                        clientLocationLogoUrl={clientLocationLogoUrl}
                        languageKey={"de"}
                        formRowsProp={[...selectedDocument.formRows]}
                        onFormChange={() => { }}
                        showNextButton={false}
                        isSaving={false}
                        showRenderPDFButton={true}
                        formUser="patient"
                    />
                </div>

            </Dialog>}

            <Box className="kt-page">
                <h2>Dokumente</h2>

                <Grid container>
                    <Grid item>
                        <ButtonGroup>
                            <Button onClick={onCreateDocumentClick} startIcon={<AddCircleOutlineIcon />}>
                                Dokument anlegen
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>

                <Box className="kt-data-grid-container">
                    <DataGridPro
                        loading={isLoading}
                        rows={pdocuments}
                        columns={columns}
                        rowReordering
                        onRowOrderChange={handleRowOrderChange}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box>

            </Box>

        </>
    );
}

export default DocumentsPage;