import { Link } from "react-router-dom";
import User from "../../models/user";
import { t } from "i18next";


interface Props {
    currentUser: null | User
}



const NotAllowedPage:React.FC<Props> = ({currentUser}) => {

    return (
        <div className="kt-loading-page">

            <div className="kt-loading-page-div">
                <h1>Pickadoc</h1>

                <img src="/images/logo.svg" alt="logo" className='kt-logo' />

                <div className="kt-grid">
                    <h2>{t("pages.notAllowedPage.title")}</h2>
                    {(currentUser && currentUser.id) ?
                        <span>{t("pages.notAllowedPage.insufficientRights")}</span>
                        :
                        <span>{t("pages.notAllowedPage.loginFirst")} <br/><Link to={`/login?target=${window.location.pathname}`} className="kt-btn kt-uppercase">{t("pages.loginPage.login")}</Link></span>
                    }

                </div>
            </div>
        </div>
    )
}

export default NotAllowedPage;