import React, { useEffect, useRef, useContext, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timeLinePlugin from '@fullcalendar/timeline';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

import Appointment, { PatientStatus } from '../../models/appointment';
import { isMobileDevice } from '../../utils';
import AppointmentsService from '../../services/appointmentsService';
import PatientsService from '../../services/patientsService';
import CalendarsService from '../../services/calendarsService';
import PublicHoliday from '../../models/publicHoliday';

import Calendar from '../../models/calendar';
import Speciality from '../../models/speciality';
import Room from '../../models/room';
import Device from '../../models/device';
import Reminder from '../../models/reminder';
import { CalendarItemType } from '../../models/calendarItem';
import MessageStatus from '../../models/messageStatus';
import Rating from '../../models/rating';

import ClientLocation from '../../models/clientLocation';
import ClientLocationsService from '../../services/clientLocationsService';
import Patient, { PatientDocsStatus } from '../../models/patient';
import RatingsService from '../../services/ratingsService';
import NotificationsService from '../../services/notificationsService';
import NotificationDialog from '../dialogs/notificationDialog';
import DateUtils from '../../shared/src/utils/dateUtils';
import { CalendarApi, DateSpanApi, EventApi, EventContentArg, EventHoveringArg } from '@fullcalendar/core';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { GlobalContext } from '../../GlobalContext';
import LoggingService from '../../services/loggingService';
import { LogItemType } from '../../shared/src/models/logItem';
import i18next, { t } from 'i18next';


export type TimeRangeViewType = "none" | "listDay" | "resourceTimeGridDay" | "resourceTimeGridWeek" | "dayGridMonth";

interface Props {
    appointments: Appointment[],
    resources: Calendar[],
    specialities: Speciality[],
    rooms: Room[],
    devices: Device[],
    selectedDate: Date,
    selectedView: "calendars" | "devices" | "rooms",
    mode: string,
    onDateChange: (newDate: any) => void,
    onTimeRangeViewChange: (newView: TimeRangeViewType) => void
}

const initialContextMenuData = {
    mouseX: null,
    mouseY: null,
    type: "appointment" as CalendarItemType
};

const CalendarCtrl: React.FC<Props> = ({ appointments, resources, specialities, rooms, devices, selectedDate, selectedView, mode, onDateChange, onTimeRangeViewChange }) => {

    const [firstDay, setFirstDay] = useState(1);
    const [contextMenuData, setContextMenuData] = useState<{ mouseX: number | null, mouseY: number | null, type: CalendarItemType }>(initialContextMenuData);
    const [selectedContextMenuAppointment, setSelectedContextMenuAppointment] = useState<EventContentArg | null>(null);

    const [isNotificationDialogVisible, setIsNotificationDialogVisible] = useState(false);

    const [selectedNotificationItem, setSelectedNotificationItem] = useState<any>();

    const { calendars, setCalendars } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);
    const { currentLocation } = useContext(GlobalContext);
    const { setIsAppointmentDialogVisible, setSelectedAppointment } = useContext(GlobalContext);

    const [clientLocation, setClientLocation] = useState<null | ClientLocation>(null);

    const { calendarViewSettings, setCalendarViewSettings } = useContext(GlobalContext);


    let calendarRef = useRef<FullCalendar>(null);
    let calendarApi: CalendarApi | null = null;



    useEffect(() => {
        console.log(`view has changed ${selectedView}`);
    }, [selectedView]);

    useEffect(() => {

        async function updateClientLocation() {
            if (currentUser) {
                const location = await ClientLocationsService.getLocation(currentClient.id, currentUser.locationId, false);
                if (location) setClientLocation(location);
            }
        }

        updateClientLocation();

        updateFirstDayOfWeek();

    }, []);


    useEffect(() => {
        // check if selected date has changed, for example by clicking the in the date picker
        if (selectedDate && calendarApi) {
            updateFirstDayOfWeek();
            calendarApi.gotoDate(selectedDate);
        }
    }, [selectedDate]);


    async function onAppointmentClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, info: EventContentArg) {

        console.log("onAppointmentClick");

        // check if user clicked on a public holiday or similar date
        if (info && info.event.allDay) {
            return;
        }

        if (info) {
            const calendarItemType: CalendarItemType = info.event.extendedProps.calendarItemType;
            if (calendarItemType === "ratingRequest" || calendarItemType === "reminder") {

                // close notification details from preview panel
                handleEventMouseLeave();

                openNotificationDialog(info.event.id, calendarItemType);

                return;
            }
        }

        let _appointmentId = "";

        if (info && info.event && info.event.id) {
            _appointmentId = info.event.id;
        }

        if (_appointmentId && currentUser) {
            const appointment = await AppointmentsService.getAppointment(_appointmentId, currentUser.clientId, currentUser.locationId);
            if (appointment) {

                // close appointment details from preview panel
                handleEventMouseLeave();

                setSelectedAppointment(appointment.clone());

                setIsAppointmentDialogVisible(true);
            }
        }

    }

    function onDateClick(clickEvent) {

        const duration = 30; // minutes

        if (clickEvent && clickEvent.date && clickEvent.resource && currentUser && currentClient) {

            if (mode === "notifications") {

                if (DateUtils.isInFuture(clickEvent.date)) {
                    const reminder = new Reminder();
                    reminder.calendarItemType = "reminder";
                    reminder.clientId = currentClient.id;
                    reminder.clientName = currentClient.name;
                    reminder.locationId = currentUser.locationId;
                    reminder.start = clickEvent.date;
                    reminder.sendAt = clickEvent.date;
                    reminder.resourceId = clickEvent.resource.id;
                    reminder.calendarId = clickEvent.resource.id;
                    //reminder.end = new Date(clickEvent.date.getTime() + duration*60000);

                    setSelectedNotificationItem(reminder);
                    setIsNotificationDialogVisible(true);

                    return;

                } else {
                    alert("Sie können neue Nachrichten nur in der Zukunft erstellen.");
                    return;
                }
            }

            const newAppointment = new Appointment();
            newAppointment.clientId = currentClient.id;
            newAppointment.locationId = currentUser.locationId;
            newAppointment.start = clickEvent.date;
            newAppointment.end = new Date(clickEvent.date.getTime() + duration * 60000); // ToDo: get this from user config

            switch (clickEvent.resource.extendedProps.typeName) {

                case "room":
                    newAppointment.roomId = clickEvent.resource.id;
                    newAppointment.roomName = clickEvent.resource.title;
                    break;

                case "device":
                    newAppointment.deviceId = clickEvent.resource.id;
                    newAppointment.deviceName = clickEvent.resource.title;
                    break;

                default:
                    newAppointment.resourceId = clickEvent.resource.id;
                    //newAppointment.calendar.id = clickEvent.resource.id;
                    break;
            }

            // open appointment dialog
            setSelectedAppointment(newAppointment);
            setIsAppointmentDialogVisible(true);
        }
    }


    async function openNotificationDialog(notificationId: string, calendarItemType: CalendarItemType) {

        if (calendarItemType === "ratingRequest") {
            const ratingRequest = await RatingsService.getRating(notificationId);
            if (ratingRequest) {
                setSelectedNotificationItem(ratingRequest);
                setIsNotificationDialogVisible(true);
            }
        } else if (calendarItemType === "reminder") {
            let reminder = await NotificationsService.getReminder(notificationId);

            // if not found then it was already sent and stored in the clients location reminders list
            if (reminder === null && currentUser) {
                reminder = await NotificationsService.getSentReminder(notificationId, currentClient.id, currentUser.locationId);
            }

            if (reminder) {
                setSelectedNotificationItem(reminder);
                setIsNotificationDialogVisible(true);
            }
        }
    }





    function updateFirstDayOfWeek() {
        const now = new Date();
        const isMonthView = calendarApi && calendarApi.view.type === "dayGridMonth"

        const firstDate = selectedDate ? selectedDate : now;
        setFirstDay(isMonthView ? 1 : firstDate.getDay());
    }


    function getResourceName(resource) {

        if (calendarRef && calendarRef.current && !calendarApi) {
            calendarApi = calendarRef.current.getApi();
        }

        if (resource && calendarApi) {

            const viewType = calendarApi.view.type;

            let resourceCaption = (resource.parentName ? resource.parentName + " - " : "") + resource.name;

            // on mobile devices show only full calendar name if we are in day view and only one calendar is selected
            if (isMobileDevice() && resources.filter(r => r.selected).length > 1) {
                return resource.getShortName();
            }

            // in week view always show short calendar names
            if (viewType === "resourceTimeGridWeek" && typeof resource.getShortName === "function") {
                return resource.getShortName();
            }

            return resourceCaption;
        }

        return "";
    }

    type resourceType = {
        id: string,
        title: string,
        typeName: "calendar" | "device" | "room",
        userId: string,
        parent: resourceType | null,
        businessHours: any
    }

    function getBusinessHours(calendar: Calendar): any {
        const result = [] as any;

        for (let i = 0; i < 7; i++) {
            const day = calendar.openingHours.getWorkingDayByIndex(i);

            if (day.hasOpen) {

                if (day.hasPause) {
                    result.push(
                        {
                            daysOfWeek: [i],
                            startTime: day.open.start.toString(),
                            endTime: day.pause.start.toString()
                        }
                    );

                    result.push(
                        {
                            daysOfWeek: [i],
                            startTime: day.pause.end.toString(),
                            endTime: day.open.end.toString()
                        }
                    );

                } else {
                    result.push(
                        {
                            daysOfWeek: [i],
                            startTime: day.open.start.toString(),
                            endTime: day.open.end.toString()
                        }
                    );
                }

            }
        }

        return result;
    }

    function getFilteredResources(): resourceType[] {

        switch (selectedView) {
            case "rooms":
                return rooms.filter(r => r.selected).map(room => {
                    return {
                        id: room.id,
                        title: getResourceName(room),
                        typeName: "room",
                        userId: "",
                        parent: null,
                        businessHours: null
                    };
                }
                );

            case "devices":
                return devices.filter(d => d.selected).map(dev => {
                    return {
                        id: dev.id,
                        title: getResourceName(dev),
                        typeName: "device",
                        userId: "",
                        parent: null,
                        businessHours: null
                    };
                }
                );

            default:
                return resources.filter(r => r.selected).map(res => {
                    return {
                        id: res.id,
                        title: getResourceName(res),
                        typeName: "calendar",
                        userId: res.userId,
                        parent: null,
                        businessHours: mode === "notifications" ? null : getBusinessHours(res)
                    };
                }
                );
        }

    }

    function getFilteredAppointments(): (Appointment | PublicHoliday | Reminder | Rating)[] {
        const result: (Appointment | PublicHoliday | Reminder | Rating)[] = [];

        const filteredResourcesIds = resources.filter(r => r.selected).map(r => r.id);
        const filteredSpecialitiesIds = specialities.filter(s => s.selected).map(s => s.id);
        const filteredRoomsIds = rooms.filter(r => r.selected).map(r => r.id);
        const filteredDevicesIds = devices.filter(d => d.selected).map(d => d.id);

        for (let i = 0; i < appointments.length; i++) {

            const appointment = appointments[i] as any;
            const calendarItemType: CalendarItemType = appointment.calendarItemType;
            const isPublicHoliday = calendarItemType === "publicHoliday";

            appointment.borderColor = calendarItemType === "absence" ? "#405064" : appointment.color;
            appointment.className = (appointment.patientStatus === PatientStatus.treated || appointment.messageStatus === MessageStatus.successfullySendToGateway) ? "kt-low-opacity" : "";
            appointment.className = (appointment.status === "needsConfirmation" || appointment.status === "declined") ? "kt-ghost-opacity" : appointment.className;
            appointment.className += appointment.status === "declined" ? " kt-line-through" : "";
            appointment.className += (appointment.patient?.newPatient && calendarItemType !== "absence") ? " kt-new-patient" : "";

            let isItemFiltered = false;

            switch (selectedView) {
                case "rooms":
                    if (filteredRoomsIds.includes("without-room") && !appointment.roomId) {
                        appointment.resourceId = "without-room";
                    } else {
                        isItemFiltered = !filteredRoomsIds.includes(appointment.roomId);
                        appointment.resourceId = isItemFiltered ? "" : appointment.roomId;
                    }

                    break;

                case "devices":
                    if (filteredDevicesIds.includes("without-device") && !appointment.deviceId) {
                        appointment.resourceId = "without-device";
                    } else {
                        isItemFiltered = !filteredDevicesIds.includes(appointment.deviceId);
                        appointment.resourceId = isItemFiltered ? "" : appointment.deviceId;
                    }

                    break;

                default:
                    const calendarId = appointment.calendarId ? appointment.calendarId : (appointment.calendar ? appointment.calendar.id : "");
                    isItemFiltered = !(calendarId && filteredResourcesIds.includes(calendarId));
                    appointment.resourceId = isItemFiltered ? "" : calendarId;
                    break;
            }


            if (calendarItemType !== "reminder" && calendarItemType !== "ratingRequest" && calendarItemType !== "absence") {
                if (!(appointment.visitMotive && appointment.visitMotive.specialityId && filteredSpecialitiesIds.includes(appointment.visitMotive.specialityId))) {
                    isItemFiltered = true;
                }
            }

            // allways show public holidays and allways for all resources
            if (isPublicHoliday) {
                appointment.resourceIds = filteredResourcesIds;
                isItemFiltered = false;
            }

            // set color for ratingRequests and reminders
            if (calendarItemType === "reminder") {
                appointment.borderColor = "#00ae72";
            } else if (calendarItemType === "ratingRequest") {
                appointment.borderColor = "orange";
            }

            if (!(clientLocation && clientLocation.showVirtualAppointments)) {
                if (appointment.status === "needsConfirmation" || appointment.status === "declined") {
                    isItemFiltered = true;
                }
            }

            if (!isItemFiltered) {
                result.push(appointment);
            }
        }

        return result;
    }

    function renderAllDayEventContent(info: any) {
        return <div className="kt-publicHoliday"><i className="fal fa-umbrella-beach"></i> <span className="kt-title">{info.event.title}</span></div>
    }

    function renderEventContent(info: EventContentArg) {

        if (info.event.allDay) {
            return renderAllDayEventContent(info);
        }

        const now = Date.now();

        const calendarItemType: CalendarItemType = info.event.extendedProps.calendarItemType;

        let sendBy: "" | "sms" | "email" = "";

        if (calendarItemType === "reminder") {
            sendBy = info.event.extendedProps.sendBy;
        } else if (calendarItemType === "ratingRequest") {
            sendBy = info.event.extendedProps.sendBy;
        }

        const lastName = info.event.extendedProps.patient ? info.event.extendedProps.patient.lastName : info.event.title;

        if (info.view.type === "resourceTimeGridDay" || info.view.type === "resourceTimeGridWeek") {

            let className = "kt-appointment-wrapper";

            className += info.event.extendedProps.patientStatus === PatientStatus.inWaitingRoom ? " kt-shaded" : "";

            className += calendarItemType === "absence" ? " kt-absence" : "";

            let docStatusClassName = "kt-documents-status";
            if (info.event.extendedProps.patient && calendarItemType === "appointment") {
                // if (info.event.extendedProps.documentsExpireAt && info.event.extendedProps.documentsExpireAt.getTime() >= now) {
                //     docStatusClassName += " kt-docs-complete";
                // } else if (info.event.extendedProps.documentsSent === true) {
                //     docStatusClassName += " kt-docs-sent";
                // } else {
                //     docStatusClassName += " kt-docs-incomplete";
                // }
                const patientDocsStatus = info.event.extendedProps.patientDocsStatus as PatientDocsStatus;
                if (patientDocsStatus === "red") {
                    docStatusClassName += " kt-docs-incomplete";
                } else if (patientDocsStatus === "yellow") {
                    docStatusClassName += " kt-docs-sent";
                } else {
                    docStatusClassName += " kt-docs-complete";
                }
            }

            return <div className={className} onClick={e => onAppointmentClick(e, info)} onContextMenu={e => handleAppointmentContextMenuClick(e, info)}>
                <div className={docStatusClassName}></div>
                <div className="kt-appointment-head">
                    <div className="kt-appointment-title">
                        {DateUtils.getTimeString(info.event.start!)} {calendarItemType === "absence" ? <strong className='kt-secondary-color'>ABWESENHEIT</strong> : <strong>{lastName}</strong>}
                    </div>
                    <div className="kt-appointment-icons">
                        {sendBy === "sms" && <div><i className="fas fa-mobile-alt" /></div>}
                        {sendBy === "email" && <div><i className="fas fa-at" /></div>}

                        {calendarItemType === "reminder" && <div><i className="fas fa-alarm-clock" /></div>}
                        {calendarItemType === "ratingRequest" && <div><i className="fas fa-star" /></div>}

                        {/* {createdBy === "predecessor" && <div><i className="fas fa-robot"/></div>}
                        {createdBy === "recaller" && <div><i className="fas fa-robot"/></div>} */}

                        {info.event.extendedProps.isVideoCall && <div><i className="fas fa-video" /></div>}
                        {info.event.extendedProps.status === "needsConfirmation" && <div className="kt-needs-confirmation"><i className="fal fa-question-circle" /></div>}
                        {/* {info.event.extendedProps.chainId && <div><i className="fal fa-link" /></div>} */}
                        {/* {info.event.extendedProps.patient.newPatient && <div><i className="fas fa-user-plus" /></div>}*/}
                    </div>
                </div>


                <div className="kt-appointment-details">
                    {(calendarItemType === "appointment" && info.event.extendedProps.patient?.privateInsurance) && <i className="fas fa-star" />}
                    {info.event.extendedProps.comments}
                </div>

            </div>
        } else if (info.view.type === "dayGridMonth") {
            let className = calendarItemType === "absence" ? " kt-absence" : "";

            return <div onClick={e => onAppointmentClick(e, info)} onContextMenu={e => handleAppointmentContextMenuClick(e, info)} className={className}>
                <div className="fc-daygrid-event-dot" style={{ borderColor: info.event.borderColor }}></div>
                <span className="fc-event-time">{DateUtils.getTimeString(info.event.start!)} {calendarItemType === "absence" ? <span className='kt-secondary-color'>ABWESENHEIT</span> : lastName}</span>
                {info.event.extendedProps.status === "needsConfirmation" && <span> <i className="fal fa-question-circle" /></span>}
            </div>;
        } else {
            let className = calendarItemType === "absence" ? " kt-absence" : "";

            return <div onClick={e => onAppointmentClick(e, info)} onContextMenu={e => handleAppointmentContextMenuClick(e, info)} className={className}>
                {DateUtils.getTimeString(info.event.start!)} {calendarItemType === "absence" ? <span className='kt-secondary-color'>ABWESENHEIT</span> : lastName}
            </div>;
        }

    }

    async function handleAppointmentContextMenuClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, info: EventContentArg) {

        const calendarItemType: CalendarItemType = info.event.extendedProps.calendarItemType;

        if (calendarItemType === "appointment" || calendarItemType === "absence") {
            setSelectedContextMenuAppointment(info);

            setContextMenuData({
                mouseX: e.clientX - 2,
                mouseY: e.clientY - 4,
                type: calendarItemType
            });

        }

        e.preventDefault();
    }

    function handleAppointmentContextMenuClose() {
        setContextMenuData(initialContextMenuData);

        handleEventMouseLeave();
    }

    async function handleEventDrop(eventDropInfo) {

        const e: any = eventDropInfo; // we need the cast to 'any', because otherwise we get a typescript error for 'oldEvent', 'oldResource' and 'newResource'

        if (eventDropInfo.event.extendedProps.calendarItemType !== "appointment" && eventDropInfo.event.extendedProps.calendarItemType !== "absence") {
            // cancel drop
            eventDropInfo.revert();
            return;
        }

        const realyMove = window.confirm(`Wollen Sie den Termin wirklich verschieben?`);

        if (realyMove && currentUser) {

            const patient = await PatientsService.getPatient(eventDropInfo.event.extendedProps.patient.id, currentClient.id, currentUser.locationId);
            const appointment = await AppointmentsService.getAppointment(eventDropInfo.event.id, currentClient.id, currentUser.locationId);

            if (patient && appointment) {

                const oldStart = new Date(appointment.start);
                const oldCalendarName = appointment.calendar.name;

                if (eventDropInfo.event.start && eventDropInfo.event.end) {
                    appointment.start = eventDropInfo.event.start;
                    appointment.end = eventDropInfo.event.end;
                }

                if (e.newResource && e.newResource.id && e.newResource.title) {

                    switch (e.newResource.extendedProps.typeName) {

                        case "room":
                            appointment.roomId = e.newResource.id;
                            appointment.roomName = e.newResource.title;
                            break;

                        case "device":
                            appointment.deviceId = e.newResource.id;
                            appointment.deviceName = e.newResource.title;
                            break;

                        default:

                            const calendar = await CalendarsService.getCalendar(currentClient.id, currentLocation.id, e.newResource.id);

                            if (calendar) {
                                appointment.calendar.id = e.newResource.id;
                                appointment.calendar.name = calendar.name;
                            }

                            break;
                    }

                }

                // log this event
                if (eventDropInfo.event.extendedProps.calendarItemType === "appointment") {
                    let message = `${appointment.getTitle()} für ${appointment.patient.lastName} von ${DateUtils.getDateTimeString(oldStart)} auf ${DateUtils.getDateTimeString(appointment.start)} verschoben`;

                    if (oldCalendarName !== appointment.calendar.name) {
                        message = `${appointment.getTitle()} für ${appointment.patient.lastName} von ${oldCalendarName} ${DateUtils.getDateTimeString(oldStart)} auf ${appointment.calendar.name} ${DateUtils.getDateTimeString(appointment.start)} verschoben`;
                    }

                    LoggingService.log(LogItemType.userAction, appointment.id, "", appointment.patient.id, appointment.calendar.userId, currentUser.id, message, appointment.clientId, appointment.locationId);
                }

                await AppointmentsService.updateAppointment(appointment, patient, currentClient, currentLocation, currentUser.id, undefined, undefined, (newAppointment) => {

                });


            }

        } else {
            // cancel drop
            eventDropInfo.revert();
        }
    }

    async function handleEventResize(eventResizeInfo: EventResizeDoneArg) {

        if (eventResizeInfo.event.extendedProps.calendarItemType !== "appointment" && eventResizeInfo.event.extendedProps.calendarItemType !== "absence") {
            // cancel resize
            eventResizeInfo.revert();
            return;
        }

        if (currentUser) {
            let patient: Patient | null = new Patient();

            if (eventResizeInfo.event.extendedProps.calendarItemType !== "absence") {
                patient = await PatientsService.getPatient(eventResizeInfo.event.extendedProps.patient.id, currentUser.clientId, currentUser.locationId);
            }

            const appointment = await AppointmentsService.getAppointment(eventResizeInfo.event.id, currentClient.id, currentUser.locationId);

            if (patient && appointment) {

                if (eventResizeInfo.event.start && eventResizeInfo.event.end) {
                    appointment.start = eventResizeInfo.event.start;
                    appointment.end = eventResizeInfo.event.end;
                }

                await AppointmentsService.updateAppointment(appointment, patient, currentClient, currentLocation, currentUser.id, undefined, undefined, (newAppointment) => {

                });
            }
        }

    }


    function handleEventMouseEnter(eventMouseEnterInfo: EventHoveringArg) {

        const toasterList = document.getElementById("kt-logItem-list");
        if (toasterList) {
            toasterList.style.display = "none";
        }

        const panel = document.getElementById("kt-appointment-preview-panel");

        if (panel) {

            const appointment = new Appointment();
            appointment.fromObject(null, eventMouseEnterInfo.event.extendedProps);

            if (appointment && appointment.patient && appointment.visitMotive) {

                if (eventMouseEnterInfo.event.extendedProps.calendarItemType && eventMouseEnterInfo.event.extendedProps.calendarItemType === "publicHoliday") {
                    panel.innerHTML = ``;
                    return;
                }

                const lastName = eventMouseEnterInfo.event.extendedProps.patient ? eventMouseEnterInfo.event.extendedProps.patient.lastName : "";
                const firstName = eventMouseEnterInfo.event.extendedProps.patient ? eventMouseEnterInfo.event.extendedProps.patient.firstName : "";

                const phone = appointment.patient.getMobileOrPhoneNumber();

                const timeString = `${DateUtils.getTimeString(eventMouseEnterInfo.event.start)} - ${DateUtils.getTimeString(eventMouseEnterInfo.event.end)}`;

                if (appointment.calendarItemType === "absence") {
                    let innerHTML = `<div class="kt-preview-panel-content" >`;

                    innerHTML += `<div class="kt-visit-motive">ABWESENHEIT</div>`;
                    innerHTML += `<div><i class="fal fa-clock"></i> ${timeString}</div>`;

                    innerHTML += appointment.comments ? `<p>${appointment.comments}</p>` : "";

                    innerHTML += "</div>";

                    panel.innerHTML = innerHTML;

                } else {
                    let innerHTML = `<div class="kt-preview-panel-content" style="border-left-color: ${appointment.visitMotive.color}">`;

                    innerHTML += `<div class="kt-visit-motive">${appointment.getTitle()}</div>`;
                    innerHTML += `<p><strong>${lastName}</strong> ${firstName}</p>`;
                    innerHTML += `<div><i class="fal fa-clock"></i> ${timeString}</div>`;
                    innerHTML += phone ? `<div><i class="fal fa-phone"></i> ${phone}</div>` : "";

                    innerHTML += appointment.patient.privateInsurance ? `<p class="kt-private-insurance"><i class="fas fa-star"></i> Privat versichert</p>` : ``;
                    innerHTML += appointment.patient.newPatient ? `<p class="kt-new-patient"><i class="fal fa-user-plus"></i> Neuer Patient</p>` : ``;

                    innerHTML += appointment.roomName ? `<p class="kt-room"><i class="fal fa-door-closed"></i> ${appointment.roomName}</p>` : ``;
                    innerHTML += appointment.deviceName ? `<p class="kt-device"><i class="fal fa-pager"></i> ${appointment.deviceName}</p>` : ``;

                    innerHTML += appointment.isVideoCall ? `<p class="kt-video-call"><i class="fal fa-video"></i> Videosprechstunde</p>` : ``;

                    innerHTML += appointment.status === "needsConfirmation" ? `<p class="kt-needs-confirmation"><i class="fal fa-question-circle"></i> nicht bestätigt</p>` : ``;
                    innerHTML += appointment.status === "declined" ? `<p class="kt-needs-confirmation"><i class="fal fa-times-circle"></i> abgesagt</p>` : ``;

                    if (appointment.calendarItemType !== "reminder") {
                        innerHTML += appointment.comments ? `<p>${appointment.comments}</p>` : "";
                    }

                    //innerHTML += `<div class="kt-border-bottom"></div>`;

                    innerHTML += "</div>";

                    panel.innerHTML = innerHTML;
                }
            }
        }
    }

    function handleEventMouseLeave() {

        const panel = document.getElementById("kt-appointment-preview-panel");

        if (panel) {
            panel.innerHTML = ``;
        }

        const toasterList = document.getElementById("kt-logItem-list");
        if (toasterList) {
            toasterList.style.display = "block";
        }
    }

    async function handleSetPatientStatusClick(newStatus: PatientStatus) {
        if (selectedContextMenuAppointment && currentUser) {
            AppointmentsService.setNewPatientStatus(selectedContextMenuAppointment.event.id, currentClient, currentUser?.locationId, newStatus);
        }
        handleAppointmentContextMenuClose();
    }

    async function handleDeleteAppointmentClick() {

        if (selectedContextMenuAppointment) {
            let message = "";

            if (selectedContextMenuAppointment.event.extendedProps.calendarItemType === "absence") {
                message = `Wollen Sie diese Abwesenheit wirklich löschen?`;
            } else {
                message = `Wollen Sie diesen Termin mit ${selectedContextMenuAppointment.event.extendedProps.patient.lastName} wirklich löschen?`;
            }

            if (currentUser && window.confirm(message)) {
                AppointmentsService.deleteAppointmentById(
                    selectedContextMenuAppointment.event.id,
                    selectedContextMenuAppointment.event.extendedProps.patient.id,
                    currentClient.id,
                    currentUser?.locationId,
                    currentUser?.id,
                    selectedContextMenuAppointment.event.extendedProps.chainId,
                    selectedContextMenuAppointment.event.extendedProps.recallId,
                    selectedContextMenuAppointment.event.extendedProps.successorId
                );
            }

        }

        handleAppointmentContextMenuClose();
    }

    function handleEventAllow(dropLocation: DateSpanApi, draggedEvent: EventApi | null): boolean {

        if ((selectedView === "devices" || selectedView === "rooms") && draggedEvent && draggedEvent.start) {
            return dropLocation.start.getHours() === draggedEvent.start.getHours() && dropLocation.start.getMinutes() === draggedEvent.start.getMinutes();
        }

        return true;
    }




    return (
        <>
            <NotificationDialog
                visible={isNotificationDialogVisible}
                notificationItem={selectedNotificationItem}
                onClose={() => setIsNotificationDialogVisible(false)}
                onSave={() => setIsNotificationDialogVisible(false)}
            />

            <div className={mode === "notifications" ? "kt-calendarCtrl-wrapper kt-livebot" : "kt-calendarCtrl-wrapper"} onContextMenu={e => { e.preventDefault() }}>

                <Menu
                    keepMounted
                    open={contextMenuData.mouseY !== null}
                    onClose={handleAppointmentContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenuData.mouseY !== null && contextMenuData.mouseX !== null
                            ? { top: contextMenuData.mouseY, left: contextMenuData.mouseX }
                            : undefined
                    }
                >
                    {contextMenuData.type === "appointment" && <MenuItem onClick={e => handleSetPatientStatusClick(PatientStatus.inWaitingRoom)}>Ins Wartezimmer setzen</MenuItem>}
                    {contextMenuData.type === "appointment" && <MenuItem onClick={e => handleSetPatientStatusClick(PatientStatus.inTreatment)}>In Behandlung</MenuItem>}
                    {contextMenuData.type === "appointment" && <MenuItem onClick={e => handleSetPatientStatusClick(PatientStatus.treated)}>Behandelt</MenuItem>}

                    {/* {contextMenuData.type === "appointment" && <MenuItem>SMS senden</MenuItem>} */}

                    <MenuItem onClick={handleDeleteAppointmentClick}>Löschen</MenuItem>
                </Menu>

                <FullCalendar
                    schedulerLicenseKey='0313295822-fcs-1691738715'
                    ref={calendarRef}
                    initialView={CalendarsService.restoreInitialView()}
                    plugins={[dayGridPlugin, timeGridPlugin, timeLinePlugin, listPlugin, interactionPlugin, resourceTimeGridPlugin]}
                    events={getFilteredAppointments()}
                    resources={getFilteredResources()}
                    resourceOrder="name"
                    locale={i18next.language}
                    editable={true}
                    //eventClick={onAppointmentClick}
                    dateClick={onDateClick}
                    //slotMinWidth={1200}
                    customButtons={{
                        prev: {
                            text: "<",
                            click: () => {
                                calendarApi?.prev();
                                onDateChange(calendarApi?.getDate())
                            }
                        },
                        next: {
                            text: ">",
                            click: () => {
                                calendarApi?.next();
                                onDateChange(calendarApi?.getDate())
                            }
                        },
                        today: {
                            text: t("components.calendarCtrl.buttons.today"),
                            click: () => {
                                calendarApi?.today();
                                onDateChange(calendarApi?.getDate())
                            }
                        }
                    }}
                    headerToolbar={{
                        left: isMobileDevice() ? 'prev,next today' : 'prev,next today',
                        center: 'title',
                        right: 'listDay,resourceTimeGridDay,resourceTimeGridWeek,dayGridMonth'
                    }}
                    buttonText={
                        isMobileDevice() ? {
                            today: t("components.calendarCtrl.mobileButtons.today"),
                            month: t("components.calendarCtrl.mobileButtons.month"),
                            week: t("components.calendarCtrl.mobileButtons.week"),
                            day: t("components.calendarCtrl.mobileButtons.day"),
                            list: t("components.calendarCtrl.mobileButtons.list")
                        }
                            :
                            {
                                today: t("components.calendarCtrl.buttons.today"),
                                month: t("components.calendarCtrl.buttons.month"),
                                week: t("components.calendarCtrl.buttons.week"),
                                day: t("components.calendarCtrl.buttons.day"),
                                list: t("components.calendarCtrl.buttons.list")
                            }
                    }
                    allDaySlot={true}
                    allDayContent=""
                    slotMinTime={calendarViewSettings.slotMinTime}
                    slotMaxTime={calendarViewSettings.slotMaxTime}
                    height="100%"
                    expandRows={true}
                    slotEventOverlap={false}
                    nowIndicator={true}
                    weekNumberCalculation="ISO" // starts always with monday
                    firstDay={firstDay} // 0 = sunday, 1 = monday ...
                    //hiddenDays={[0]}
                    //weekNumbers={true}
                    //slotDuration={`00:${zoomFactor}:00`}
                    //slotLabelInterval={{minutes:30}}
                    views={{
                        listDay: {
                            titleFormat: { year: 'numeric', month: isMobileDevice() ? 'short' : 'long', day: '2-digit' }
                        },
                        resourceTimeGridDay: {
                            titleFormat: { year: 'numeric', month: isMobileDevice() ? 'short' : 'long', day: '2-digit' }
                        },
                        resourceTimeGridWeek: {
                            titleFormat: { year: isMobileDevice() ? '2-digit' : 'numeric', month: isMobileDevice() ? 'short' : 'long' }
                        },
                        dayGridMonth: { // name of view
                            titleFormat: { year: 'numeric', month: isMobileDevice() ? 'short' : 'long' }
                        }
                    }}
                    // businessHours={[ // specify an array instead
                    //     {
                    //         daysOfWeek: [ 1, 2, 3 ], // Monday, Tuesday, Wednesday
                    //         startTime: '08:00', // 8am
                    //         endTime: '18:00' // 6pm
                    //     },
                    //     {
                    //         daysOfWeek: [ 4, 5 ], // Thursday, Friday
                    //         startTime: '09:00', // 10am
                    //         endTime: '16:00' // 4pm
                    //     }
                    // ]}
                    eventContent={(info) => renderEventContent(info)}
                    datesAboveResources={true}
                    viewDidMount={(arg) => {
                        // this is not called when switching between day and week view
                        calendarApi = arg.view.calendar;

                        updateFirstDayOfWeek();

                    }}
                    viewClassNames={(arg) => {
                        // gets called on every view change (dayList, day, week, month)

                        if (localStorage) {
                            // hack to force resource name rerender,
                            // because arg.view.calendar.refetchResources() does not work
                            const oldViewName = localStorage.getItem("initialView");
                            if (oldViewName && oldViewName !== arg.view.type) {
                                setCalendars(calendars);
                            }
                        }

                        CalendarsService.saveCalendarInitialView(arg.view.type);

                        onTimeRangeViewChange(arg.view.type as TimeRangeViewType);

                        return [];
                    }}
                    refetchResourcesOnNavigate={true}
                    eventDrop={(eventDropInfo) => handleEventDrop(eventDropInfo)}
                    eventResize={(eventResizeInfo) => handleEventResize(eventResizeInfo)}
                    eventMouseEnter={(eventMouseEnterInfo) => (handleEventMouseEnter(eventMouseEnterInfo))}
                    eventMouseLeave={(eventMouseLeaveInfo) => (handleEventMouseLeave())}
                    eventAllow={(dateSpan, event) => handleEventAllow(dateSpan, event)}
                />
            </div>


        </>

    );


}

export default CalendarCtrl;