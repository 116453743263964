import { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from "react-router-dom";

import firebaseApp from "./database";

import UsersService from "./../services/usersService";
import SearchInput from './searchInput';
import PatientDialog from './dialogs/patientDialog/patientDialog';
import Patient from '../../src/models/patient';
import { GlobalContext } from '../GlobalContext';
import CalendarViewSettingsDialog from './dialogs/calendarViewSettingsDialog';
import LanguageSelectorCtrl from './languageSelectorCtrl';
import { t } from 'i18next';


export default function TopNavBar() {

    const location = useLocation();

    const [searchText, setSearchText] = useState("");
    const [isDopdownVisible, setIsDropdownVisible] = useState(false);
    const { currentUser } = useContext(GlobalContext);
    const { isSidebarVisible, setIsSidebarVisible } = useContext(GlobalContext);
    const { currentClient } = useContext(GlobalContext);
    const { videoSidebarVisible, setVideoSidebarVisible } = useContext(GlobalContext);

    const [isPatientDialogVisible, setIsPatientDialogVisible] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(new Patient());

    const [isCalendarViewSettingsDialogVisible, setIsCalendarViewSettingsDialogVisible] = useState(false);


    useEffect(() => {

        function hideDropdownOrSidebar(e) {
            if (typeof e.target.className !== "string" || e.target.className.indexOf("dropdown-toggle-button") === -1) {
                setTimeout(() => setIsDropdownVisible(false), 100);
            }

            if (e.target.getAttribute("data-day") || (typeof e.target.className === "string" && e.target.className.indexOf("kt-side-menu-close-button") !== -1)) {
                setTimeout(() => setIsSidebarVisible(false), 100);
            }
        }

        document.body.addEventListener('click', hideDropdownOrSidebar, true);
        return () => document.body.removeEventListener('click', hideDropdownOrSidebar);

        // eslint-disable-next-line
    }, []);

    function getClassName() {
        return isLocation("recaller") || isLocation("campaign") ? "top-nav-bar-recaller" : "";
    }

    function logout() {
        firebaseApp.auth().signOut();
    }

    function isLocation(page) {
        return location.pathname.toLowerCase().indexOf(page.toLowerCase()) !== -1;
    }

    function isRoot() {
        return location.pathname.toLowerCase() === "/";
    }

    function isCalendRPage() {
        return location.pathname.toLowerCase() === "/calendr";
    }

    function isSettingsRoot() {
        return location.pathname.toLowerCase() === "/settings";
    }

    function handleSearchPatientChange(newPatient) {
        if (newPatient) {

            setSelectedPatient(newPatient);
            setIsPatientDialogVisible(true);

            setSearchText("");
        }
    }

    function showVideoSidebar() {
        setVideoSidebarVisible(true);
        localStorage.setItem("videoSideobarVisible", "true");
    }

    const openCalendarViewSettingsDialog = () => {
        setIsCalendarViewSettingsDialogVisible(true);
    }

    // render only close button if sidebar is open
    if (isSidebarVisible) {
        return (
            <header className="topNavBar noselect">
                <div className="kt-side-menu-close-button kt-hide-on-desktop"><i className="fal fa-times-circle"></i> {t("components.navBar.close")}</div>
            </header>
        )
    }

    return (

        <>
            <CalendarViewSettingsDialog
                visible={isCalendarViewSettingsDialogVisible}
                onClose={() => setIsCalendarViewSettingsDialogVisible(false)}
            />

            <PatientDialog
                visible={isPatientDialogVisible}
                patient={selectedPatient}
                onClose={() => setIsPatientDialogVisible(false)}
                onSave={() => setIsPatientDialogVisible(false)}
            />

            <header className="topNavBar noselect">
                <nav className={getClassName()}>
                    <Link to="/" className="navbar-brand" rel="noreferrer" >
                        <img src="/images/logo.png" width={32} alt="PickADoc Logo" />

                        <span className="navbar-brand-name">Pickadoc</span>
                    </Link>

                    {UsersService.isSuperUser(currentUser) ?
                        <ul className="navbar-nav navbar-nav-desktop kt-main-nav">
                            <li>
                                <i className='fa fa-exclamation-triangle'></i> {t("components.navBar.superUserAccount")} <i className='fa fa-exclamation-triangle'></i>
                            </li>
                            <li className={isLocation("clients") ? "isSelected" : ""}>
                                <Link to="/administration/clients" ><i className="fal fa-hospital"></i> {t("components.navBar.clients")}</Link>
                            </li>
                        </ul>
                        :
                        isLocation("/settings") ? <ul className="navbar-nav navbar-nav-desktop kt-main-nav">
                        </ul>
                            :
                            <ul className="navbar-nav navbar-nav-desktop kt-main-nav">
                                {currentUser &&
                                    <li className="search">
                                        <SearchInput
                                            value={searchText}
                                            simplePlaceholder={t("components.navBar.patientSearch")}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            onPatientChange={handleSearchPatientChange}
                                            onBlur={() => setSearchText("")}
                                            clientId={currentUser.clientId}
                                            locationId={currentUser.locationId}
                                            searchIcon={true}
                                            style={{ margin: 0 }}
                                        />
                                    </li>
                                }
                                <li className={isLocation("waitingroom") ? "isSelected" : ""}>
                                    <Link to="/waitingroom" ><i className="fal fa-chair"></i> {t("components.navBar.waitingroom")}</Link>
                                </li>
                                <li className={isRoot() ? "isSelected" : ""}>
                                    <Link to="/" ><i className="fal fa-calendar-alt" aria-hidden="true"></i> {t("components.navBar.appointments")}</Link>
                                </li>
                                {/* <li className={isLocation("recaller") ? "isSelected" : ""}>
                                <Link to="/recaller" ><i className="fal fa-phone-plus" aria-hidden="true"></i> RECALLER</Link>
                            </li> */}
                                {currentClient?.features.hasToDo && <li className={isLocation("todos") ? "isSelected" : ""}>
                                    <Link to="/todos" ><i className="fa fa-clipboard-list-check"></i> {t("components.navBar.tasks")}</Link>
                                </li>}
                                <li className={isLocation("patients") ? "isSelected" : ""}>
                                    <Link to="/patients" className="nav-link" ><i className="fal fa-address-card" aria-hidden="true"></i> {t("components.navBar.patients")}</Link>
                                </li>
                                {(currentClient?.features?.hasDashboard && currentUser && currentUser.isAdmin) &&
                                    <li className={isLocation("dashboard") ? "isSelected" : ""}>
                                        <Link to="/dashboard" className="nav-link" ><i className="fal fa-tachometer-alt-fast" aria-hidden="true"></i> {t("components.navBar.dashboard")}</Link>
                                    </li>
                                }

                                {/* <li className="search">
                            <span className="fa fa-search"></span>
                            <input className="form-control" type="search" placeholder="Patient/in suchen" aria-label="Patient/in suchen" />
                        </li> */}
                            </ul>
                    }

                    {UsersService.isSuperUser(currentUser) ?
                        <ul className="navbar-nav navbar-nav-mobile">

                        </ul>
                        :
                        isLocation("/settings") ? <ul className="navbar-nav navbar-nav-mobile">
                        </ul>
                            :
                            <ul className="navbar-nav navbar-nav-mobile">
                                <li className={isLocation("waitingroom") ? "isSelected" : ""}>
                                    <Link to="/waitingroom" ><i className="fal fa-chair"></i></Link>
                                </li>
                                <li className={isRoot() ? "isSelected" : ""}>
                                    <Link to="/" className="nav-link" ><i className="fal fa-calendar-alt" aria-hidden="true"></i></Link>
                                </li>
                                {currentClient?.features.hasToDo && <li className={isLocation("todos") ? "isSelected" : ""}>
                                    <Link to="/todos" ><i className="fa fa-clipboard-list-check"></i></Link>
                                </li>}
                                <li className={isLocation("patients") ? "isSelected" : ""}>
                                    <Link to="/patients" className="nav-link" ><i className="fal fa-address-card" aria-hidden="true"></i></Link>
                                </li>
                                {(currentClient?.features?.hasDashboard && currentUser && currentUser.isAdmin) &&
                                    <li className={isLocation("dashboard") ? "isSelected" : ""}>
                                        <Link to="/dashboard" className="nav-link" ><i className="fal fa-tachometer-alt-fast" aria-hidden="true"></i></Link>
                                    </li>
                                }
                            </ul>
                    }

                    <ul className="navbar-settings kt-desktop">
                        <li className="kt-username">{currentUser?.title ? (currentUser?.title + " " + currentUser?.lastName.toUpperCase()) : currentUser?.lastName.toUpperCase()}</li>
                        <li className="dropdown">
                            <div className="nav-link dropdown-toggle dropdown-toggle-button" onClick={() => setIsDropdownVisible(!isDopdownVisible)}>
                                <i className="fa fa-user-cog dropdown-toggle-button"></i> <i className="fas fa-caret-down dropdown-toggle-button"></i>
                            </div>

                            {UsersService.isSuperUser(currentUser) && isDopdownVisible && <div className="dropdown-menu">
                                <div className="dropdown-item" onClick={logout}><i className="fa fa-sign-out-alt"></i> {t("components.navBar.logout")}</div>
                            </div>}

                            {!UsersService.isSuperUser(currentUser) && isDopdownVisible && <div className="dropdown-menu">
                                {(currentUser && currentUser.isAdmin) && <Link to="/settings" className="dropdown-item"><i className="fa fa-wrench"></i> {t("components.navBar.settings")}</Link>}
                                <div className="dropdown-item" onClick={openCalendarViewSettingsDialog}><i className="fa fa-sliders-h"></i> {t("components.navBar.view")}</div>
                                {(currentUser && currentUser.isAdmin) && <div className="dropdown-divider"></div>}
                                <div className="dropdown-item" onClick={logout}><i className="fa fa-sign-out-alt"></i> {t("components.navBar.logout")}</div>
                            </div>}
                        </li>

                        {!UsersService.isSuperUser(currentUser) && <li className="nav-item nav-item-desktop">
                            <a target='_blank' rel="noopener noreferrer" href={`https://pickadoc.de/profile/${currentClient?.id}/${currentUser?.locationId}`} className="nav-link">
                                <i className="fa fa-hospital"></i>
                            </a>
                        </li>}
                        <li style={{ marginLeft: '10px' }}>
                            <LanguageSelectorCtrl />
                        </li>
                        <li className="nav-item nav-item-desktop">
                            {/* <a target='_blank' rel="noopener noreferrer" href={`https://pickadoc.de/profile/${currentClient?.id}/${currentUser?.locationId}`} className="nav-link">
                                <i className="fa fa-question-circle"></i>
                            </a> */}
                            <button className="nav-link" onClick={() => showVideoSidebar()}>
                                <i className="fa fa-question-circle"></i>
                            </button>
                        </li>

                    </ul>


                    <ul className="navbar-settings kt-mobile" onClick={() => setIsSidebarVisible(!isSidebarVisible)}>

                        <li className="kt-link kt-toggle-sidebar">
                            <i className="fal fa-bars kt-toggle-sidebar"></i>
                        </li>

                    </ul>

                </nav>
            </header>

        </>



    );
}
