import React, { useState, useEffect, ChangeEvent, useRef } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";

import {insertAtCursor} from "../utils";
import TextEvaluationService from '../services/textEvaluationService';
import ShortUrlsService from '../services/shortUrlsService';

interface Props {
    name: string;
    smsText: string;
    clientId: string;
    locationId: string;
    templateMode: boolean;
    onValueChange: (newValue: string) => void
}


const SmsEditor: React.FC<Props> = ({ name, smsText, clientId, locationId, templateMode, onValueChange }) => {

    const maxSmsLength = 160;

    const textAreaRef = useRef();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogSmsText, setDialogSmsText] = useState("");
    const [smsPreviewText, setSmsPreviewText] = useState("");
    const [characterCount, setCharacterCount] = useState(0);

    useEffect(() => {
        const _previewText = TextEvaluationService.evaluateWithDummyData(dialogSmsText);
        setSmsPreviewText(_previewText);
    }, [dialogSmsText]);

    useEffect(()=>{
        setSmsPreviewText(TextEvaluationService.evaluateWithDummyData(smsText));

    }, [smsText]);

    useEffect(() => {
        if(smsPreviewText){
            setCharacterCount(smsPreviewText.length);
        }
    }, [smsPreviewText]);


    function onSmsEditorButtonClick() {
        setDialogSmsText(smsText);
        setIsDialogOpen(true);
    }


    function handleSaveDialog(){
        onValueChange(dialogSmsText);

        setIsDialogOpen(false);
    }

    function handleAbortDialog(){
        setSmsPreviewText(TextEvaluationService.evaluateWithDummyData(smsText));
        setIsDialogOpen(false);
    }

    function onDialogSmsTextChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        let newText = e.target.value;

        newText = newText.substring(0, maxSmsLength);

        setDialogSmsText(newText);

        const _previewText = TextEvaluationService.evaluateWithDummyData(newText);
        setSmsPreviewText(_previewText);
    }


    function insertText(textToInsert: string){

        if(textAreaRef.current !== undefined && textToInsert && textToInsert !== "none"){
            const element = (textAreaRef.current as any) as HTMLTextAreaElement;
            let newText = insertAtCursor(element, textToInsert);

            newText = newText.substring(0, maxSmsLength);

            setDialogSmsText(newText);
        }

    }

    async function insertAppointmentBookingLink() {
        const link = `https://pickadoc.de/profile/${clientId}/${locationId}`;

        const expiresAt = moment(new Date()).add(24, "months").toDate();
        const shortLink = await ShortUrlsService.createShortUrl(link, expiresAt);

        insertText(shortLink);
    }

    function getTextLengthLabel(): string {
        return `Noch ca. ${maxSmsLength - characterCount} Zeichen übrig`;
    }

    return (

        <div>

            <TextField
                fullWidth
                rows={5}
                name={name}
                value={smsPreviewText}
                label="SMS-Text"
                multiline
                InputProps={{
                    readOnly: true,
                }}
            />

            <Button
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={onSmsEditorButtonClick}
            >
                SMS-Text-Editor öffnen
            </Button>

            <Dialog
                className="kt-smsEditor"
                open={isDialogOpen}
                onClose={handleSaveDialog}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">SMS-Text-Editor</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {templateMode ?
                            "Erstellen Sie hier Ihre SMS-Vorlage für den Besuchsgrund"
                            :
                            "Erstellen und ändern Sie hier Ihren SMS-Nachrichtentext"
                        }

                    </DialogContentText>

                    <Grid container>
                        <Grid item xs={12} sm={6}>

                            {templateMode && <Button onClick={()=>insertText("[[TerminLink]]")} variant="outlined" className="kt-insert-button">Platzhalter für Termin-Landing-Page-Link einfügen</Button>}
                            <Button onClick={()=>insertAppointmentBookingLink()} variant="outlined" className="kt-insert-button">Link zur Online-Termin-Buchung einfügen</Button>
                            {templateMode && <Button onClick={()=>insertText("[[TerminDatum]]")} variant="outlined" className="kt-insert-button">Platzhalter für Termin-Datum einfügen</Button>}
                            {templateMode && <Button onClick={()=>insertText("[[TerminUhrzeit]]")} variant="outlined" className="kt-insert-button">Platzhalter für Termin-Uhrzeit einfügen</Button>}


                            <TextField
                                autoFocus
                                margin="dense"
                                rows={10}
                                name={"editor"+name}
                                value={dialogSmsText}
                                label={templateMode ? "SMS-Text-Vorlage" : "SMS-Nachrichtentext"}
                                multiline
                                fullWidth
                                onChange={onDialogSmsTextChange}
                                inputRef={textAreaRef}
                                className="kt-dialogSmsText"
                                inputProps={{ maxLength: maxSmsLength }}
                            />
                            <div className={characterCount>maxSmsLength ? "kt-character-count-label kt-error" : "kt-character-count-label"}>
                                {getTextLengthLabel()}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box textAlign="center">
                                <DialogContentText><strong>Vorschau</strong></DialogContentText>
                                <div style={{position: "relative", width: "300px", margin: "auto"}}>
                                    <img src="../../images/iphone.jpg" alt="" width="100%"/>
                                    <div className="kt-smsPreviewField-wrapper">
                                        <div className="kt-smsPreviewField bubble-bottom-left">{smsPreviewText}</div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleAbortDialog} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleSaveDialog} color="primary">
                        Übernehmen
                    </Button>
                </DialogActions>
            </Dialog>

        </div>

    );
}

export default SmsEditor;
