import { Suspense, useEffect, useState } from 'react';

import UsersService from "./services/usersService";
import SettingsService from "./services/settingsService";


import firebaseApp from "./components/database";

import Client from "./models/client";

import ClientsService from './services/clientsService';
import VisitMotivesService from './services/visitMotivesService';
import CalendarsService from './services/calendarsService';
import PublicHolidaysService from './services/publicHolidaysService';
import SpecialitiesService from './services/specialitiesService';
import ClientLocationsService from './services/clientLocationsService';

import RoomsService from './services/roomsService';
import DevicesService from './services/devicesService';
import LoggingService from './services/loggingService';

import { LicenseInfo } from '@mui/x-license-pro';
import DisabledPage from './components/pages/disabledPage';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { deDE } from '@mui/material/locale';

import dayjs from "dayjs";
import 'dayjs/locale/de';
import { GlobalContext } from './GlobalContext';
import Room from './models/room';
import GlobalSettings from '../shared/src/models/globalSettings';
import User from './models/user';
import Device from './models/device';
import Speciality from './models/speciality';
import VisitMotive from './models/visitMotive';
import Calendar from './models/calendar';
import PublicHoliday from './models/publicHoliday';
import ClientLocation from './models/clientLocation';
import LogItem from './shared/src/models/logItem';
import Toaster, { Toast } from './components/toaster';
import AppRoutes from './components/pages/appRoutes';
import CalendarViewSettings from './models/calendarViewSettings';
import AppointmentDialog from './components/dialogs/appointmentDialog/appointmentDialog';
import Appointment from './models/appointment';
import { Box } from '@mui/material';

var customParseFormat = require("dayjs/plugin/customParseFormat");

LicenseInfo.setLicenseKey('095cef4f8eaea9baba1977e9c8735878Tz04MzQ5MSxFPTE3Mzg1MTU4OTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

dayjs.locale("de");
dayjs.extend(customParseFormat);



function App() {

    const [settings, setSettings] = useState<GlobalSettings>();

    const [currentClient, setCurrentClient] = useState<Client>(new Client());
    const [currentUser, setCurrentUser] = useState<User | null>(null);

    const [rooms, setRooms] = useState<Room[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);

    const [specialities, setSpecialities] = useState<Speciality[]>([]);
    const [visitMotives, setVisitMotives] = useState<VisitMotive[]>([]);
    const [calendars, setCalendars] = useState<Calendar[]>([]);
    const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [currentLocation, setCurrentLocation] = useState<ClientLocation>(new ClientLocation());

    const [isLoading, setIsLoading] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");
    const [isProgressMessageVisible, setIsProgressMessageVisible] = useState(false);
    const [newToast, setNewToast] = useState<Toast>(new Toast("info", ""));

    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [videoSidebarVisible, setVideoSidebarVisible] = useState(false);

    const [calendarViewSettings, setCalendarViewSettings] = useState(new CalendarViewSettings());

    const [selectedAppointment, setSelectedAppointment] = useState(new Appointment());
    const [isAppointmentDialogVisible, setIsAppointmentDialogVisible] = useState(false);

    const [loadingCounter, setLoadingCounter] = useState(0);


    useEffect(() => {
        setIsLoading(loadingCounter > 0);

        // eslint-disable-next-line
    }, [loadingCounter]);

    useEffect(() => {

        setLoadingCounter(loadingCounter + 1); // for onAuthStateChanged

        async function fetchPublicHolidays(client: Client) {
            if (client && client.id) {
                const today = new Date();

                setLoadingCounter(loadingCounter + 1);

                const pHolidays = await PublicHolidaysService.getPublicHolidays(client.country, client.state, today.getFullYear() - 1, 3);
                setLoadingCounter(loadingCounter - 1);
                if (pHolidays) {
                    setPublicHolidays(pHolidays);
                }
            }
        }

        return firebaseApp.auth().onAuthStateChanged(async function (user) {
            if (user) {
                // User is signed in.


                try {
                    //  const setSuperUserClaims = functions().httpsCallable("setSuperUserClaims");
                    //  await setSuperUserClaims();

                    const idTokenResult = await user.getIdTokenResult();

                    const role = idTokenResult.claims.role;
                    const isAdmin = idTokenResult.claims.isAdmin;
                    const clientId = idTokenResult.claims.clientId;

                    const userResult = await UsersService.getUser(clientId, user.uid);

                    if (userResult && role) {
                        userResult.role = role;
                        setCurrentUser(userResult);

                        // console.log(`${role} user logged in: ${user.email}`);
                        // console.log(`user has admin rights: ${isAdmin}`);

                        const clientResult = await ClientsService.getClient(userResult.clientId);
                        if (clientResult) {
                            setCurrentClient(clientResult);
                            //console.log(`client: ${clientResult.name}`);

                            fetchPublicHolidays(clientResult);

                            const usersResult = await UsersService.getUsersByLocationId(userResult.clientId, userResult.locationId);
                            setUsers(usersResult);

                            const locationResult = await ClientLocationsService.getLocation(clientId, userResult.locationId);
                            if (locationResult) {
                                setCurrentLocation(locationResult);
                            }

                            let calendarsResult = await CalendarsService.getCalendars(true, userResult.clientId, userResult.locationId);
                            if (calendarsResult) {

                                // now check initial subscription status
                                if (calendarsResult.length > 0 && calendarsResult[0].subscriptionId === "") {

                                    // show subscription selection dialog

                                }

                                // restore calendars selection from local storage
                                calendarsResult = CalendarsService.restoreCalendarsSelection(calendarsResult);

                                setCalendars(calendarsResult);
                            }
                        }

                    } else {
                        alert("Ihre Berechtigungen reichen nicht aus, um diese Seite zu besuchen. Bitte wenden Sie Sich an den Administrator dieser Seite.")
                    }

                    setLoadingCounter(0);

                    const globalSettings = await SettingsService.getGlobalSettings();
                    if (globalSettings) setSettings(globalSettings);

                } catch (error) {
                    //setCurrentUser(null);

                    console.log("Error: " + (error as any).message);

                    setLoadingCounter(0);
                }


            } else {
                // User is signed out.
                setCurrentUser(null);

                console.log("user logged out");
                setLoadingCounter(0);
            }
        });

        // eslint-disable-next-line
    }, []);


    useEffect(() => {

        // load view settings from local storage
        const vSettings = CalendarsService.restoreCalendarViewSettings();
        setCalendarViewSettings(vSettings);

        if (currentUser && currentUser.clientId) {

            (async () => {

                let _rooms = await RoomsService.getRooms(currentUser.clientId, currentUser.locationId);
                if (_rooms) {

                    // restore rooms selection from local storage
                    _rooms = RoomsService.restoreRoomsSelection(_rooms);

                    setRooms(_rooms);
                }

                let _devices = await DevicesService.getDevices(currentUser.clientId, currentUser.locationId);
                if (_devices) {

                    // restore devices selection from local storage
                    _devices = DevicesService.restoreDevicesSelection(_devices);

                    setDevices(_devices);
                }


                let _specialities = await SpecialitiesService.getSpecialities(currentUser.clientId, currentUser.locationId);
                if (_specialities) {

                    // restore specialities selection from local storage
                    _specialities = SpecialitiesService.restoreSpecialitiessSelection(_specialities);

                    setSpecialities(_specialities);
                }

                const _visitMotives = await VisitMotivesService.getVisitMotives(currentUser.clientId, currentUser.locationId);
                if (_visitMotives) {

                    if (_specialities) {
                        for (let v = 0; v < _visitMotives.length; v++) {
                            const visitMotive = _visitMotives[v];

                            for (let s = 0; s < _specialities.length; s++) {
                                const speciality = _specialities[s];
                                if (visitMotive.specialityId === speciality.id) {
                                    visitMotive.color = speciality.color;
                                }
                            }

                        }
                    }

                    setVisitMotives(_visitMotives);

                }

            })();

        }
    }, [currentUser]);



    useEffect(() => {
        if (currentClient && currentLocation && users && users.length > 0 && calendars && calendars.length > 0 && currentLocation) {
            for (let i = 0; i < calendars.length; i++) {
                const calendar = calendars[i];

                // get the user of that calendar
                const user = users.find(u => u.id === calendar.userId);

                // now check if the user has custom working/opening hours
                if (user && user.openingHours && user.openingHours.enabled) {
                    calendar.openingHours = user.openingHours.clone();

                } else {
                    // if not use the locations opening hours
                    calendar.openingHours = currentLocation.openingHours.clone();
                }
            }

            setCalendars(calendars);
        }

    }, [currentClient, currentLocation, users, calendars])


    const theme = createTheme({
        palette: {
            primary: {
                main: "#d83ac3"
            },
            secondary: {
                main: "#24374e"
            }
        },
        components: {
            MuiInputBase: {
                defaultProps: {
                    margin: 'dense',
                    size: 'small'
                }
            },
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                    margin: "dense"
                }
            },
            MuiFormControl: {
                defaultProps: {
                    variant: "outlined",
                    margin: "dense",
                    size: 'small'
                }
            },
            MuiGrid: {
                defaultProps: {
                    spacing: 4
                }
            }
        }

    }, deDE);


    //console.log("render App");

    if (currentUser && currentClient && currentClient.isEnabled === false) {
        return <DisabledPage />
    }

    // if(isLoading) return <>Laden...</>

    const LoadingTranslation = () => {
        return <Box sx={{ display: "flex" }}>
            <>Loading translations...</>
        </Box>
    }

    return (
        <Suspense fallback={<LoadingTranslation />} >
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <GlobalContext.Provider value={{
                        isLoading, setIsLoading,
                        rooms, setRooms,
                        settings, setSettings,
                        currentClient, setCurrentClient,
                        currentUser, setCurrentUser,
                        devices, setDevices,
                        specialities, setSpecialities,
                        visitMotives, setVisitMotives,
                        calendars, setCalendars,
                        publicHolidays, setPublicHolidays,
                        users, setUsers,
                        currentLocation, setCurrentLocation,
                        progressMessage, setProgressMessage,
                        isProgressMessageVisible, setIsProgressMessageVisible,
                        videoSidebarVisible, setVideoSidebarVisible,
                        isSidebarVisible, setIsSidebarVisible,
                        newToast, setNewToast,
                        calendarViewSettings, setCalendarViewSettings,
                        isAppointmentDialogVisible, setIsAppointmentDialogVisible,
                        selectedAppointment, setSelectedAppointment
                    }}>
                        <AppointmentDialog
                            visible={isAppointmentDialogVisible}
                            appointment={selectedAppointment}
                            onClose={() => setIsAppointmentDialogVisible(false)}
                            onSave={() => setIsAppointmentDialogVisible(false)}
                        />
                        <Toaster toasterId="kt-toaster" newToast={newToast} />
                        <AppRoutes />
                    </GlobalContext.Provider>
                </LocalizationProvider>
            </ThemeProvider>
        </Suspense>
    );
}

export default App;
