import { useEffect, useContext } from "react";

import LoadingPage from "../administration/loadingPage";
import { useState } from "react";
import LogItem from "../../../../src/shared/src/models/logItem";
import LoggingService from "../../../../src/services/loggingService";
import Box from "@mui/material/Box";
import { GlobalContext } from "../../../GlobalContext";
import DateUtils from "../../../shared/src/utils/dateUtils";

function LogItemsPage() {

    const [isLoading, setIsLoading] = useState(false);
    const { currentClient } = useContext(GlobalContext);
    const { currentUser } = useContext(GlobalContext);

    const [logItems, setLogItems] = useState<LogItem[]>([]);

    useEffect(() => {

        if (currentClient) {
            updateData();
        }

        // eslint-disable-next-line
    }, [currentClient]);



    async function updateData() {

        if (!currentUser) {
            return;
        }

        try {
            const cId = currentClient.id;

            setIsLoading(true);

            //const result = await LoggingService.getLogItemsForMonth(cId, currentUser.locationId, 2022, 5);
            const result = await LoggingService.getLogItemsForLast30Days(cId, currentUser.locationId);

            if (result) {
                setLogItems(result);
            }



            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }


    if (isLoading) return <LoadingPage />;


    function renderLogItem(item: LogItem) {
        return (
            <div
                key={item.id}
                style={
                    { 
                        color: item.getColor(), 
                        borderBottom: "1px solid lightgrey", 
                        padding: "3px",
                        display: "grid",
                        gridTemplateColumns: "40px 150px 1fr",                        
                    }
                }
            >
                <i className={item.getCssIconString()} style={{marginTop: "3px"}}></i>
                <span>{`${DateUtils.getDateString(item.createdAt)} ${DateUtils.getTimeString(item.createdAt)}`}</span>
                <span>{item.toStringShort()}</span>
            </div>
        )
    }

    return (
        <Box className="kt-page">
            <h2>Ereignisse der letzten 30 Tage

            </h2>

            {logItems.map(item => renderLogItem(item))}
        </Box>
    );
}

export default LogItemsPage;