import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";

import DataTable from "../../dataTable";

import ClientsService from "../../../services/clientsService";
import LoadingPage from './loadingPage';
import Client from '../../../models/client';
import { GlobalContext } from '../../../GlobalContext';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { deDE } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClientLocationsService from '../../../services/clientLocationsService';
import DateUtils from '../../../shared/src/utils/dateUtils';

function ClientsPage() {

    const navigate = useNavigate();

    const [clients, setClients] = useState<Client[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        loadData();

        // eslint-disable-next-line
    }, []);

    const loadData = async () => {
        setIsLoading(true);

        const _clients = await ClientsService.getAllClients();

        // if (_clients) {
        //     for (let i = 0; i < _clients.length; i++) {
        //         const _client = _clients[i];

        //         _client.locations = await ClientLocationsService.getAllLocations(_client.id) ?? [];
        //     }
        // }

        setIsLoading(false);
        if (_clients) setClients(_clients);
    }

    const columns: any[] = [

        {
            field: 'name', headerName: 'Name', width: 300, renderCell: (params) => (
                <Link to={`/administration/client/${params.row.id}`}>{params.row.name}</Link>
            )
        },
        {
            field: 'city', headerName: 'Stadt', width: 300, renderCell: (params) => (
                <Link to={`/administration/client/${params.row.id}`}>{params.row.city}</Link>
            )
        },
        {
            field: 'createdAt', headerName: 'Erstellt', width: 300, renderCell: (params) => (
                <>{params.row.createdAt ?
                    <span>{DateUtils.getDateTimeString(params.row.createdAt)}</span>
                    :
                    <span></span>}
                </>
            )
        },
        // {
        //     field: 'locations', headerName: 'Standorte', width: 300, renderCell: (params) => (
        //         <Link to={`/administration/client/${params.row.id}`}>{params.row.locations?.length}</Link>
        //     )
        // },
        // { field: 'landingpage', headerName: 'Landingpage', width: 300, renderCell: (params) => (
        //     <Link to={`https://pickadoc.de/profile/${params.row.id}`}>LP</Link>
        // )},
        // {
        //     field: 'delete', headerName: " ", width: 64, renderCell: (params) => (
        //         <IconButton onClick={(e) => onDeleteRowClick(params.row)}>
        //             <DeleteIcon />
        //         </IconButton>)
        // },
        {
            field: 'details', headerName: " ", width: 64, renderCell: (params) => (
                <IconButton>
                    <Link to={`/administration/client/${params.row.id}`}><i className="fal fa-info-circle"></i></Link>
                </IconButton>)
        }
    ];


    async function onDeleteRowClick(row) {
        const reallyDelete = window.confirm(`Wollen Sie den Kunden ${row.name} wirklich löschen?`);
        if (reallyDelete) {
            await ClientsService.deleteClient(row.id);
            loadData();
        }

    }

    if (isLoading) return <LoadingPage />;

    return (
        <Box className="kt-page">
            <h2>Kunden</h2>

            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <Button component={Link} to="https://pickadoc.de/installation" startIcon={<AddCircleOutlineIcon />}>
                            Kunden anlegen
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Box className="kt-data-grid-container">
                <DataGridPro
                    loading={isLoading}
                    rows={clients}
                    columns={columns}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

        </Box>
    );
}

export default ClientsPage;